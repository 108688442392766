# based on https://github.com/lovasoa/react-contenteditable/blob/master/index.js
MediumEditor = require '../mixins/react-medium-editor'

ContentEditable = createReactClass
  mixins: [MediumEditor]

  propTypes:
    html: PropTypes.string
    contentEditable: PropTypes.bool
    className: PropTypes.string
    autoFocus: PropTypes.bool
    mediumEditorOptions: PropTypes.object
    onChange: PropTypes.func

  getDefaultProps: ->
    contentEditable: true
    className: "content"
    autoFocus: true

  shouldComponentUpdate: (nextProps, nextState) ->
    nextProps.html isnt ReactDOM.findDOMNode(this).innerHTML or
    nextProps.contentEditable isnt @props.contentEditable or
    nextProps.className isnt @props.className

  componentDidMount: ->
    @focus() if @props.autoFocus

  componentDidUpdate: ->
    @focus() if @props.autoFocus

  focus: ->
    if @props.contentEditable
      ReactDOM.findDOMNode(this).focus()

  render: ->

    props = _(@props).omit 'html', 'mediumEditorOptions'
    <div {...props} dangerouslySetInnerHTML={{__html: @props.html}} />

module.exports = ContentEditable
