var EVENTS, ReactMediumEditorMixin, gdtMediumOptions;

gdtMediumOptions = require('lib/medium_editor_gdt_options');

EVENTS = ['blur', 'change', 'click', 'input', 'keydown', 'keypress', 'keyup', 'selectionchange', 'textInput'];

ReactMediumEditorMixin = {
  displayName: 'ReactMediumEditor',
  getDefaultProps: function() {
    return {
      mediumEditorOptions: gdtMediumOptions["default"]
    };
  },
  componentDidMount: function() {
    var domNode, _ref;
    domNode = ReactDOM.findDOMNode(this);
    if (this.props.contentEditable && this.props.withEditor) {
      this.medium = new MediumEditor(domNode, this.props.mediumEditorOptions);
    } else if (this.props.contentEditable) {
      this.medium = new MediumEditor(domNode, {
        toolbar: false,
        paste: {
          forcePlainText: true,
          cleanPastedHTML: false
        }
      });
    }
    EVENTS.forEach((function(_this) {
      return function(evtName) {
        return _this.medium.on(domNode, evtName, function(e) {
          return e.stopPropagation();
        });
      };
    })(this));
    return this.currentMediumId = (_ref = this.medium) != null ? _ref.id : void 0;
  },
  componentWillUnmount: function() {
    if (this.medium && this.currentMediumId !== this.medium.id) {
      return this.medium.destroy();
    }
  }
};

module.exports = ReactMediumEditorMixin;
