var Entity, OutcomeViewType,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Entity = require('base/models/entity');

OutcomeViewType = (function(_super) {
  __extends(OutcomeViewType, _super);

  function OutcomeViewType() {
    return OutcomeViewType.__super__.constructor.apply(this, arguments);
  }

  return OutcomeViewType;

})(Entity);

module.exports = {
  OneRow: new OutcomeViewType({
    name: 'OneRow',
    tableTemplate: 'views/templates/evidence_syntheses/outcomes_one_row',
    rowTemplate: 'views/templates/evidence_syntheses/outcome_one_row',
    rowClassName: 'outcome-one-row',
    questionPresent: true,
    orientation: 'landscape'
  }),
  SOF: new OutcomeViewType({
    name: 'SOF',
    tableTemplate: 'views/templates/evidence_syntheses/outcomes_sof',
    rowTemplate: 'views/templates/evidence_syntheses/outcome_sof',
    rowClassName: 'outcome-sof-row',
    questionPresent: true,
    orientation: 'portrait'
  }),
  ACCP: new OutcomeViewType({
    name: 'ACCP',
    tableTemplate: 'views/templates/evidence_syntheses/outcomes_accp',
    rowTemplate: 'views/templates/evidence_syntheses/outcome_accp',
    rowClassName: 'outcome-accp-row',
    questionPresent: true,
    orientation: 'landscape'
  }),
  SOF_ACCP: new OutcomeViewType({
    name: 'SOF_ACCP',
    tableTemplate: 'views/templates/evidence_syntheses/outcomes_sof_accp',
    rowTemplate: 'views/templates/evidence_syntheses/outcome_sof_accp',
    rowClassName: 'outcome-sof-accp-row',
    questionPresent: true,
    orientation: 'portrait'
  }),
  SOF_v3: new OutcomeViewType({
    name: 'SOF_v3',
    tableTemplate: 'views/templates/evidence_syntheses/outcomes_sof_v3',
    rowTemplate: 'views/templates/evidence_syntheses/outcome_sof_v3',
    rowClassName: 'outcome-sof-row',
    questionPresent: true,
    orientation: 'portrait'
  }),
  SOF_v4: new OutcomeViewType({
    name: 'SOF_v4',
    tableTemplate: 'views/templates/evidence_syntheses/outcomes_sof_v4',
    rowTemplate: 'views/templates/evidence_syntheses/outcome_sof_v4',
    rowClassName: 'outcome-sof-row',
    questionPresent: true,
    orientation: 'portrait'
  }),
  Tx_iSoF: new OutcomeViewType({
    name: 'Tx_iSoF',
    isof: true,
    isofType: 'tx'
  }),
  PrintoutOneRow: new OutcomeViewType({
    name: 'PrintoutOneRow',
    tableTemplate: 'views/templates/printouts/outcomes_one_row_table',
    rowTemplate: 'views/templates/evidence_syntheses/printout_one_row',
    rowClassName: 'outcome-one-row-printout-row',
    questionPresent: true,
    questionFootnotesAttr: ['healthProblemOrPopulation', 'authors', 'settings', 'bibliography', 'intervention', 'comparison', 'tableTitle']
  }),
  PrintoutSOF: new OutcomeViewType({
    name: 'PrintoutSOF',
    tableTemplate: 'views/templates/printouts/outcomes_sof_table',
    rowTemplate: 'views/templates/printouts/outcome_sof_row',
    rowClassName: 'outcome-sof-printout-row',
    questionPresent: true,
    questionFootnotesAttr: ['healthProblemOrPopulation', 'settings', 'intervention', 'comparison', 'tableTitle']
  }),
  PrintoutGRADEACCP: new OutcomeViewType({
    name: 'PrintoutGRADEACCP',
    tableTemplate: 'views/templates/printouts/outcomes_accp_grade_table',
    rowTemplate: 'views/templates/printouts/outcome_accp_grade_row',
    rowClassName: 'outcome-grade-printout-row-accp',
    questionPresent: true,
    questionFootnotesAttr: ['bibliography', 'tableTitle']
  }),
  PrintoutSOFACCP: new OutcomeViewType({
    name: 'PrintoutSOFACCP',
    tableTemplate: 'views/templates/printouts/outcomes_accp_sof_table',
    rowTemplate: 'views/templates/printouts/outcome_accp_sof_row',
    rowClassName: 'outcome-sof-printout-row-accp',
    questionPresent: true,
    questionFootnotesAttr: ['bibliography', 'tableTitle']
  }),
  PrintoutSOFv3: new OutcomeViewType({
    name: 'PrintoutSOFv3',
    tableTemplate: 'views/templates/printouts/outcomes_sof_v3_table',
    rowTemplate: 'views/templates/printouts/outcome_sof_v3_row',
    rowClassName: 'outcome-sof-v3-printout-row',
    questionPresent: true,
    questionFootnotesAttr: ['healthProblemOrPopulation', 'intervention', 'comparison', 'settings', 'tableTitle']
  }),
  PrintoutSOFv4: new OutcomeViewType({
    name: 'PrintoutSOFv4',
    tableTemplate: 'views/templates/printouts/outcomes_sof_v4_table',
    rowTemplate: 'views/templates/printouts/outcome_sof_v4_row',
    rowClassName: 'outcome-sof-v4-printout-row',
    questionPresent: true,
    questionFootnotesAttr: ['healthProblemOrPopulation', 'intervention', 'comparison', 'settings', 'tableTitle']
  }),
  LayerOne: new OutcomeViewType({
    name: 'LayerOne',
    tableTemplate: 'views/templates/evidence_syntheses/diagnostic/layer_one_table',
    rowTemplate: 'views/templates/evidence_syntheses/diagnostic/layer_one_row',
    rowClassName: 'outcome-diag-row',
    orientation: 'portrait'
  }),
  LayerTwo: new OutcomeViewType({
    name: 'LayerTwo',
    tableTemplate: 'views/templates/evidence_syntheses/diagnostic/layer_two_table',
    rowTemplate: 'views/templates/evidence_syntheses/diagnostic/layer_two_row',
    rowClassName: 'outcome-diag-row',
    orientation: 'portrait'
  }),
  LayerOneSof: new OutcomeViewType({
    name: 'LayerOneSof',
    tableTemplate: 'views/templates/evidence_syntheses/diagnostic/layer_one_sof_table',
    rowTemplate: 'views/templates/evidence_syntheses/diagnostic/layer_one_sof_row',
    rowClassName: 'outcome-diag-sof-row',
    orientation: 'landscape'
  }),
  LayerTwoSof: new OutcomeViewType({
    name: 'LayerTwoSof',
    tableTemplate: 'views/templates/evidence_syntheses/diagnostic/layer_two_sof_table',
    rowTemplate: 'views/templates/evidence_syntheses/diagnostic/layer_two_sof_row',
    rowClassName: 'outcome-diag-sof-row',
    orientation: 'landscape'
  }),
  Dx_iSoF: new OutcomeViewType({
    name: 'Dx_iSoF',
    isof: true,
    isofType: 'dx'
  }),
  PrintoutLayerOne: new OutcomeViewType({
    name: 'PrintoutLayerOne',
    tableTemplate: 'views/templates/printouts/diagnostic/layer_one_table',
    rowTemplate: 'views/templates/evidence_syntheses/diagnostic/layer_one_row',
    rowClassName: 'outcome-diag-row'
  }),
  PrintoutLayerTwo: new OutcomeViewType({
    name: 'PrintoutLayerTwo',
    tableTemplate: 'views/templates/printouts/diagnostic/layer_two_table',
    rowTemplate: 'views/templates/evidence_syntheses/diagnostic/layer_two_row',
    rowClassName: 'outcome-diag-row'
  }),
  PrintoutLayerOneSof: new OutcomeViewType({
    name: 'PrintoutLayerOneSof',
    tableTemplate: 'views/templates/printouts/diagnostic/layer_one_sof_table',
    rowTemplate: 'views/templates/evidence_syntheses/diagnostic/layer_one_sof_row',
    rowClassName: 'outcome-diag-sof-row printout'
  }),
  PrintoutLayerTwoSof: new OutcomeViewType({
    name: 'PrintoutLayerTwoSof',
    tableTemplate: 'views/templates/printouts/diagnostic/layer_two_sof_table',
    rowTemplate: 'views/templates/evidence_syntheses/diagnostic/layer_two_sof_row',
    rowClassName: 'outcome-diag-sof-row'
  }),
  Prognosis: new OutcomeViewType({
    name: "Prognosis",
    tableTemplate: "views/templates/prognosis/prognostic_table",
    rowTemplate: "views/templates/prognosis/prognostic_row",
    rowClassName: "prognostic-outcome",
    orientation: 'landscape',
    questionPresent: true
  }),
  PrintoutPrognosis: new OutcomeViewType({
    name: "PrintoutPrognosis",
    tableTemplate: "views/templates/printouts/prognosis/prognostic_table",
    rowTemplate: "views/templates/printouts/prognosis/prognostic_row",
    rowClassName: "prognostic-outcome",
    orientation: 'landscape'
  }),
  get: function(name) {
    return this[name];
  }
};
