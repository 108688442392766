var DOCUMENT_SECTIONS_V2_CHAPTER_TYPE, DOCUMENT_SECTIONS_V2_SECTION_TYPE, DocumentSectionsV2Actions, DocumentSectionsV2Store, MDA_TABLE_DOC_TYPE, MDG_WORKSPACE, PLACEMENT_AFTER, alt, convertListToOrderedMap, errorHandler, getSchema, immutable, isActiveWorkspaceOrFeatureSwitchOn, keyBy, mediator, prepareAnnotation, _ref, _ref1, _ref2,
  __slice = [].slice;

alt = require('alt');

mediator = require('mediator');

keyBy = require('lodash').keyBy;

immutable = require('stores/utils/immutable');

_ref = require('lib/app_utils'), errorHandler = _ref.errorHandler, isActiveWorkspaceOrFeatureSwitchOn = _ref.isActiveWorkspaceOrFeatureSwitchOn;

MDG_WORKSPACE = require('lib/mdg_helper').MDG_WORKSPACE;

DocumentSectionsV2Actions = require('actions/document_sections_v2_actions');

_ref1 = require('lib/db_docs/doc_types'), DOCUMENT_SECTIONS_V2_CHAPTER_TYPE = _ref1.DOCUMENT_SECTIONS_V2_CHAPTER_TYPE, DOCUMENT_SECTIONS_V2_SECTION_TYPE = _ref1.DOCUMENT_SECTIONS_V2_SECTION_TYPE, MDA_TABLE_DOC_TYPE = _ref1.MDA_TABLE;

convertListToOrderedMap = require('lib/immutable_utils').convertListToOrderedMap;

_ref2 = require('lib/document_sections_v2_helper'), PLACEMENT_AFTER = _ref2.PLACEMENT_AFTER, prepareAnnotation = _ref2.prepareAnnotation;

getSchema = Epiditor.getSchema;

DocumentSectionsV2Store = (function() {
  function DocumentSectionsV2Store() {
    this.state = Immutable.fromJS({
      _meta: {
        acceptedOrRejectedChange: null,
        activeDiscussionItem: null,
        activeSidebarTab: 'CHAPTERS',
        discussionsFilters: {
          'status': 'open',
          'author': null,
          'visibleFor': null
        },
        editingDiscussionItems: {},
        editingDiscussionReplies: {},
        expandedChapters: {},
        fetching: false,
        fetchingSectionsForSearch: false,
        fetchingChapters: {},
        sectionsForSearch: {},
        scrollToChapter: null,
        scrollToSection: null,
        scrollToSectionContent: null,
        updatedDraftDiscussion: null,
        batchEditorOptions: {}
      },
      root: null,
      chapters: {},
      sections: {},
      recommendations: {},
      mdaTables: {},
      discussions: {},
      draftDiscussions: {},
      mdaTopicId: null,
      mdaTablesSections: {}
    });
    this.bindActions(DocumentSectionsV2Actions);
    this.exportPublicMethods({
      findSectionChapter: this.findSectionChapter,
      getAcceptedOrRejectedChange: this.getAcceptedOrRejectedChange,
      getActiveDiscussionItem: this.getActiveDiscussionItem,
      getActiveSidebarTab: this.getActiveSidebarTab,
      getAlreadyUsedMdaTablesIds: this.getAlreadyUsedMdaTablesIds,
      getChapter: this.getChapter,
      getBatchEditorOptions: this.getBatchEditorOptions,
      getChapters: this.getChapters,
      getChapterSection: this.getChapterSection,
      getChapterSections: this.getChapterSections,
      getDiscussionsFilters: this.getDiscussionsFilters,
      getDraftDiscussions: this.getDraftDiscussions,
      getEditingDiscussionItems: this.getEditingDiscussionItems,
      getEditingDiscussionReplies: this.getEditingDiscussionReplies,
      getMdaTables: this.getMdaTables,
      getMdaTopicId: this.getMdaTopicId,
      getRecommendations: this.getRecommendations,
      getResultingDiscussions: this.getResultingDiscussions,
      getRoot: this.getRoot,
      getRootChaptersOrder: this.getRootChaptersOrder,
      getScrollToChapter: this.getScrollToChapter,
      getScrollToSection: this.getScrollToSection,
      getScrollToSectionContent: this.getScrollToSectionContent,
      getSection: this.getSection,
      getSections: this.getSections,
      getSectionsForSearch: this.getSectionsForSearch,
      getSectionBySectionType: this.getSectionBySectionType,
      getSuggesting: this.getSuggesting,
      getSectionsOrder: this.getSectionsOrder,
      getUpdatedDraftDiscussion: this.getUpdatedDraftDiscussion,
      isChapterExpanded: this.isChapterExpanded,
      isFetching: this.isFetching,
      isFetchingChapterSections: this.isFetchingChapterSections,
      isFetchingSectionsForSearch: this.isFetchingSectionsForSearch
    });
  }

  DocumentSectionsV2Store.prototype.onFetch = function() {
    return this.setState(this.state.setIn(['_meta', 'fetching'], true));
  };

  DocumentSectionsV2Store.prototype.onFetchSectionsForSearch = function() {
    return this.setState(this.state.setIn(['_meta', 'fetchingSectionsForSearch'], true));
  };

  DocumentSectionsV2Store.prototype.onFetchSuccess = function(_arg) {
    var chapters, discussions, mdaTables, mdaTablesSections, mdaTopicId, recommendations, root;
    root = _arg.root, chapters = _arg.chapters, recommendations = _arg.recommendations, discussions = _arg.discussions, mdaTables = _arg.mdaTables, mdaTablesSections = _arg.mdaTablesSections, mdaTopicId = _arg.mdaTopicId;
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'fetching'], false);
      state.set('root', Immutable.fromJS(root));
      state.set('chapters', Immutable.fromJS(keyBy(chapters, '_id')));
      state.set('recommendations', Immutable.fromJS(keyBy(recommendations, '_id')));
      state.set('mdaTables', Immutable.fromJS(keyBy(mdaTables, '_id')));
      state.set('mdaTablesSections', Immutable.fromJS(mdaTablesSections != null ? mdaTablesSections : {}));
      state.set('discussions', discussions.reduce(function(acc, _arg1) {
        var annotations, changeTracker, mdaTableId, sectionId;
        sectionId = _arg1.sectionId, mdaTableId = _arg1.mdaTableId, annotations = _arg1.annotations, changeTracker = _arg1.changeTracker;
        return acc.set(sectionId, Immutable.fromJS({
          annotations: annotations,
          mdaTableId: mdaTableId,
          changeTracker: changeTracker
        }));
      }, Immutable.Map()));
      return state.set('mdaTopicId', mdaTopicId != null ? mdaTopicId : null);
    }));
  };

  DocumentSectionsV2Store.prototype.onFetchSectionsForSearchSuccess = function(results) {
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['_meta', 'fetchingSectionsForSearch'], false);
      return s.setIn(['_meta', 'sectionsForSearch'], convertListToOrderedMap(Immutable.fromJS(results), 'id'));
    }));
  };

  DocumentSectionsV2Store.prototype.onFetchFailure = function(err) {
    this.setState(this.state.withMutations(function(s) {
      s.setIn(['_meta', 'fetching'], false);
      return s.setIn(['_meta', 'fetchingSectionsForSearch'], false);
    }));
    return errorHandler(err);
  };

  DocumentSectionsV2Store.prototype.onExpandChapterSections = function(chapterId) {
    return this.setState(this.state.setIn(['_meta', 'expandedChapters', chapterId], true));
  };

  DocumentSectionsV2Store.prototype.onCollapseChapterSections = function(chapterId) {
    return this.setState(this.state.withMutations(function(state) {
      var activeDiscussionItem, discussionItems, hasActiveItem;
      state.setIn(['_meta', 'expandedChapters', chapterId], false);
      activeDiscussionItem = state.getIn(['_meta', 'activeDiscussionItem']);
      if (activeDiscussionItem) {
        discussionItems = state.get('discussions').merge(state.get('draftDiscussions'));
        hasActiveItem = state.getIn(['chapters', chapterId, 'sections']).some(function(sectionId) {
          var sectionDiscussions;
          sectionDiscussions = discussionItems.get(sectionId);
          if (!sectionDiscussions) {
            return false;
          }
          return sectionDiscussions.hasIn(['annotations', activeDiscussionItem]) || sectionDiscussions.hasIn(['changeTracker', 'changes', activeDiscussionItem]);
        });
        if (hasActiveItem) {
          return state.setIn(['_meta', 'activeDiscussionItem'], null);
        }
      }
    }));
  };

  DocumentSectionsV2Store.prototype.onExpandAndFetchChapterSections = function(chapterId) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'expandedChapters', chapterId], true);
      return state.setIn(['_meta', 'fetchingChapters', chapterId], true);
    }));
  };

  DocumentSectionsV2Store.prototype.onFetchChapterSections = function(chapterId) {
    return this.setState(this.state.setIn(['_meta', 'fetchingChapters', chapterId], true));
  };

  DocumentSectionsV2Store.prototype.onFetchChapterSectionsSuccess = function(_arg) {
    var chapterId, sections;
    chapterId = _arg.chapterId, sections = _arg.sections;
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'fetchingChapters', chapterId], false);
      return state.setIn(['sections', chapterId], Immutable.fromJS(keyBy(sections, '_id')));
    }));
  };

  DocumentSectionsV2Store.prototype.onFetchChapterSectionsFailure = function(_arg) {
    var chapterId, error;
    chapterId = _arg.chapterId, error = _arg.error;
    this.setState(this.state.setIn(['_meta', 'fetchingChapters', chapterId], false));
    return errorHandler(error);
  };

  DocumentSectionsV2Store.prototype.onSetChapterProperty = function(_arg) {
    var chapterId, key, value;
    chapterId = _arg.chapterId, key = _arg.key, value = _arg.value;
    return this.setState(this.state.setIn(['chapters', chapterId, key], value));
  };

  DocumentSectionsV2Store.prototype.onSetChapterData = function(_arg) {
    var chapterId, data;
    chapterId = _arg.chapterId, data = _arg.data;
    return this.setState(this.state.mergeIn(['chapters', chapterId], data));
  };

  DocumentSectionsV2Store.prototype.onCreateChapter = function(doc) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['chapters', doc._id], Immutable.fromJS(doc));
      return state.updateIn(['root', 'chaptersOrder'], function(chaptersOrder) {
        return chaptersOrder.push(doc._id);
      });
    }));
  };

  DocumentSectionsV2Store.prototype.onDeleteChapter = function(_arg) {
    var deletedChaptersIds, deletedChaptersIdsSet, deletedSectionsIds, deletedSectionsIdsSet;
    deletedChaptersIds = _arg.deletedChaptersIds, deletedSectionsIds = _arg.deletedSectionsIds;
    deletedChaptersIdsSet = new Set(deletedChaptersIds);
    deletedSectionsIdsSet = new Set(deletedSectionsIds);
    return this.setState(this.state.withMutations(function(state) {
      state.update('chapters', function(chapters) {
        return chapters.filter(function(chapter) {
          return !deletedChaptersIdsSet.has(chapter.get('_id'));
        }).map(function(chapter) {
          return chapter.update('subchapters', function(subchaptersIds) {
            return subchaptersIds.filter(function(subchapterId) {
              return !deletedChaptersIdsSet.has(subchapterId);
            });
          });
        });
      });
      state.updateIn(['root', 'chaptersOrder'], function(chaptersOrder) {
        return chaptersOrder.filter(function(chapterId) {
          return !deletedChaptersIdsSet.has(chapterId);
        });
      });
      state.update('sections', function(sectionsIds) {
        return sectionsIds.filter(function(sectionId) {
          return !deletedSectionsIdsSet.has(sectionId);
        });
      });
      return deletedSectionsIds.forEach(function(sectionId) {
        state.deleteIn(['discussions', sectionId]);
        return state.deleteIn(['draftDiscussions', sectionId]);
      });
    }));
  };

  DocumentSectionsV2Store.prototype.onSetSectionProperty = function(_arg) {
    var chapterId, key, sectionId, value;
    chapterId = _arg.chapterId, sectionId = _arg.sectionId, key = _arg.key, value = _arg.value;
    return this.setState(this.state.setIn(['sections', chapterId, sectionId, key], value));
  };

  DocumentSectionsV2Store.prototype.onCreateSections = function(_arg) {
    var chapterId, docs;
    chapterId = _arg.chapterId, docs = _arg.docs;
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'scrollToSection'], docs[0]._id);
      state.mergeIn(['sections', chapterId], Immutable.fromJS(keyBy(docs, '_id')));
      return state.updateIn(['chapters', chapterId, 'sections'], function(sectionsIds) {
        var newSectionsIds;
        newSectionsIds = docs.map(function(doc) {
          return doc._id;
        });
        return sectionsIds.concat(Immutable.List(newSectionsIds));
      });
    }));
  };

  DocumentSectionsV2Store.prototype.onDeleteSection = function(_arg) {
    var chapterId, deletedSectionId;
    chapterId = _arg.chapterId, deletedSectionId = _arg.sectionId;
    return this.setState(this.state.withMutations(function(state) {
      state.deleteIn(['sections', chapterId, deletedSectionId]);
      state.deleteIn(['discussions', deletedSectionId]);
      state.deleteIn(['draftDiscussions', deletedSectionId]);
      return state.updateIn(['chapters', chapterId, 'sections'], function(sectionsIds) {
        return sectionsIds.filter(function(sectionId) {
          return sectionId !== deletedSectionId;
        });
      });
    }));
  };

  DocumentSectionsV2Store.prototype.onMoveChapter = function(_arg) {
    var chapterId, placement, referredChapterId;
    chapterId = _arg.chapterId, referredChapterId = _arg.referredChapterId, placement = _arg.placement;
    return this.setState(this.state.withMutations((function(_this) {
      return function(state) {
        var currentParentSubchaptersPath, newParentId, newParentSubchaptersPath;
        currentParentSubchaptersPath = _this._getParentSubchaptersPath(state, chapterId);
        state.updateIn(currentParentSubchaptersPath, function(subchaptersIds) {
          return subchaptersIds.filter(function(subchapterId) {
            return subchapterId !== chapterId;
          });
        });
        newParentId = state.getIn(['chapters', referredChapterId, 'parent']);
        state.setIn(['chapters', chapterId, 'parent'], newParentId);
        newParentSubchaptersPath = newParentId ? ['chapters', newParentId, 'subchapters'] : ['root', 'chaptersOrder'];
        return state.updateIn(newParentSubchaptersPath, function(subchaptersIds) {
          var idx;
          idx = subchaptersIds.findIndex(function(subchapterId) {
            return subchapterId === referredChapterId;
          });
          if (placement === PLACEMENT_AFTER) {
            idx++;
          }
          return subchaptersIds.splice(idx, 0, chapterId);
        });
      };
    })(this)));
  };

  DocumentSectionsV2Store.prototype._getPopulation = function(section, populationId) {
    var chapterId, path, sectionId;
    chapterId = section.get('parent');
    sectionId = section.get('_id');
    path = ['sections', chapterId, sectionId, 'additionalData', 'populations', populationId];
    return this.state.getIn(path);
  };

  DocumentSectionsV2Store.prototype.onChangeParentGroupId = function(_arg) {
    var additionalData, chapterId, newParentId, population, populationId, section, sectionId;
    section = _arg.section, populationId = _arg.populationId, newParentId = _arg.newParentId;
    chapterId = section.get('parent');
    sectionId = section.get('_id');
    population = this._getPopulation(section, populationId);
    if (!population) {
      return;
    }
    additionalData = this.state.getIn(['sections', chapterId, sectionId, 'additionalData']);
    additionalData = additionalData.withMutations(function(ad) {
      var currentOrderPath;
      currentOrderPath = population.get('parentGroup') ? ['populations', population.get('parentGroup'), 'subgroups'] : ['populationsOrder'];
      ad.updateIn(currentOrderPath, function(subgroupsIds) {
        return subgroupsIds.filter(function(subgroupId) {
          return subgroupId !== populationId;
        });
      });
      ad.setIn(['populations', populationId, 'parentGroup'], newParentId);
      return ad.updateIn(['populations', newParentId, 'subgroups'], function(subgroups) {
        return subgroups.push(populationId);
      });
    });
    return this.setState(this.state.setIn(['sections', chapterId, sectionId, 'additionalData'], additionalData));
  };

  DocumentSectionsV2Store.prototype.onMovePopulationGroup = function(_arg) {
    var additionalData, chapterId, placement, population, populationId, section, sectionId, targetPopulationId;
    section = _arg.section, populationId = _arg.populationId, targetPopulationId = _arg.targetPopulationId, placement = _arg.placement;
    chapterId = section.get('parent');
    sectionId = section.get('_id');
    population = this._getPopulation(section, populationId);
    if (!population) {
      return;
    }
    additionalData = this.state.getIn(['sections', chapterId, sectionId, 'additionalData']);
    additionalData = additionalData.withMutations(function(ad) {
      var currentOrderPath, newParentId, newParentSubchaptersPath, newSectionGroup;
      currentOrderPath = population.get('parentGroup') ? ['populations', population.get('parentGroup'), 'subgroups'] : ['populationsOrder'];
      newParentId = ad.getIn(['populations', targetPopulationId, 'parentGroup']);
      newParentSubchaptersPath = newParentId ? ['populations', newParentId, 'subgroups'] : ['populationsOrder'];
      ad.updateIn(currentOrderPath, function(subchaptersIds) {
        return subchaptersIds.filter(function(id) {
          return id !== populationId;
        });
      });
      ad.updateIn(newParentSubchaptersPath, function(subgroupIds) {
        var idx;
        idx = subgroupIds.findIndex(function(subchapterId) {
          return subchapterId === targetPopulationId;
        });
        if (placement === PLACEMENT_AFTER) {
          idx++;
        }
        return subgroupIds.splice(idx, 0, populationId);
      });
      newSectionGroup = ad.getIn(['populations', targetPopulationId, 'parentGroup']);
      return ad.setIn(['populations', populationId, 'parentGroup'], newSectionGroup);
    });
    return this.setState(this.state.setIn(['sections', chapterId, sectionId, 'additionalData'], additionalData));
  };

  DocumentSectionsV2Store.prototype.onMoveRecommendation = function(_arg) {
    var additionalData, chapterId, placement, population, populationId, recommendationId, section, sectionId, targetPopulationId, targetRecommendationId;
    section = _arg.section, recommendationId = _arg.recommendationId, populationId = _arg.populationId, targetPopulationId = _arg.targetPopulationId, targetRecommendationId = _arg.targetRecommendationId, placement = _arg.placement;
    chapterId = section.get('parent');
    sectionId = section.get('_id');
    population = this._getPopulation(section, populationId);
    if (!population) {
      return;
    }
    additionalData = this.state.getIn(['sections', chapterId, sectionId, 'additionalData']);
    additionalData = additionalData.withMutations(function(ad) {
      var pathToUpdate, updateRecommendationOrder;
      ad.updateIn(['populations', populationId, 'recommendations'], Immutable.List, function(recIds) {
        return recIds.filter(function(recId) {
          return recId !== recommendationId;
        });
      });
      updateRecommendationOrder = function(recommendationIds) {
        var idx;
        recommendationIds = recommendationIds.filter(function(recId) {
          return recId !== recommendationId;
        });
        idx = recommendationIds.findIndex(function(recId) {
          return recId === targetRecommendationId;
        });
        if (placement === PLACEMENT_AFTER) {
          idx++;
        }
        return recommendationIds.splice(idx, 0, recommendationId);
      };
      pathToUpdate = populationId === targetPopulationId ? ['populations', populationId, 'recommendations'] : ['populations', targetPopulationId, 'recommendations'];
      return ad.updateIn(pathToUpdate, Immutable.List(), updateRecommendationOrder);
    });
    return this.setState(this.state.setIn(['sections', chapterId, sectionId, 'additionalData'], additionalData));
  };

  DocumentSectionsV2Store.prototype.onChangeChapterParentId = function(_arg) {
    var chapterId, newParentId;
    chapterId = _arg.chapterId, newParentId = _arg.newParentId;
    return this.setState(this.state.withMutations((function(_this) {
      return function(state) {
        var currentParentSubchaptersPath;
        currentParentSubchaptersPath = _this._getParentSubchaptersPath(state, chapterId);
        state.updateIn(currentParentSubchaptersPath, function(subchaptersIds) {
          return subchaptersIds.filter(function(subchapterId) {
            return subchapterId !== chapterId;
          });
        });
        state.setIn(['chapters', chapterId, 'parent'], newParentId);
        return state.updateIn(['chapters', newParentId, 'subchapters'], function(subchaptersIds) {
          return subchaptersIds.push(chapterId);
        });
      };
    })(this)));
  };

  DocumentSectionsV2Store.prototype._getParentSubchaptersPath = function(state, chapterId) {
    var currentParentId;
    currentParentId = state.getIn(['chapters', chapterId, 'parent']);
    if (currentParentId) {
      return ['chapters', currentParentId, 'subchapters'];
    } else {
      return ['root', 'chaptersOrder'];
    }
  };

  DocumentSectionsV2Store.prototype.onMoveSection = function(_arg) {
    var chapterId, placement, referredSectionId, sectionId;
    chapterId = _arg.chapterId, sectionId = _arg.sectionId, referredSectionId = _arg.referredSectionId, placement = _arg.placement;
    return this.setState(this.state.updateIn(['chapters', chapterId, 'sections'], function(sectionsIds) {
      var idx, newSectionsIds;
      newSectionsIds = sectionsIds.filter(function(sId) {
        return sId !== sectionId;
      });
      idx = newSectionsIds.findIndex(function(sId) {
        return sId === referredSectionId;
      });
      if (placement === PLACEMENT_AFTER) {
        idx++;
      }
      return newSectionsIds.splice(idx, 0, sectionId);
    }));
  };

  DocumentSectionsV2Store.prototype.onChangeSectionParentId = function(_arg) {
    var chapterId, newParentId, sectionId;
    chapterId = _arg.chapterId, sectionId = _arg.sectionId, newParentId = _arg.newParentId;
    return this.setState(this.state.withMutations(function(state) {
      var section;
      state.updateIn(['chapters', chapterId, 'sections'], function(sectionsIds) {
        return sectionsIds.filter(function(sId) {
          return sId !== sectionId;
        });
      });
      state.updateIn(['chapters', newParentId, 'sections'], function(sectionsIds) {
        return sectionsIds.push(sectionId);
      });
      if (state.getIn(['sections', newParentId])) {
        section = state.getIn(['sections', chapterId, sectionId]);
        state.deleteIn(['sections', chapterId, sectionId]);
        state.setIn(['sections', newParentId, sectionId], section);
        return state.setIn(['sections', newParentId, sectionId, 'parent'], newParentId);
      }
    }));
  };

  DocumentSectionsV2Store.prototype.onSetScrollToChapter = function(chapterId) {
    return this.setState(this.state.setIn(['_meta', 'scrollToChapter'], chapterId));
  };

  DocumentSectionsV2Store.prototype.onDbChange = function(_arg) {
    var deleted, doc, id, maybeParentChapter, maybeParentChapterId, parentChapterId, _ref3;
    deleted = _arg.deleted, doc = _arg.doc, id = _arg.id;
    if (deleted) {
      if (this.state.hasIn(['chapters', id])) {
        parentChapterId = this.state.getIn(['chapters', id, 'parent']);
        return this.setState(this.state.withMutations(function(state) {
          state.deleteIn(['chapters', id]);
          if (parentChapterId != null) {
            if (state.hasIn(['chapters', parentChapterId])) {
              return state.updateIn(['chapters', parentChapterId, 'subchapters'], function(subchapters) {
                return subchapters.filterNot(function(subchapterId) {
                  return subchapterId === id;
                });
              });
            }
          } else {
            return state.update('root', function(root) {
              return root.update('chaptersOrder', function(chapters) {
                return chapters.filterNot(function(chapterId) {
                  return chapterId === id;
                });
              });
            });
          }
        }));
      } else {
        maybeParentChapter = this.state.get('chapters').find(function(chapter) {
          return chapter.hasIn(['sections', id]);
        });
        maybeParentChapterId = (_ref3 = maybeParentChapter != null ? maybeParentChapter.get('_id') : void 0) != null ? _ref3 : this.state.get('sections').findKey(function(sections) {
          return sections.has(id);
        });
        if (maybeParentChapterId != null) {
          return this.setState(this.state.withMutations(function(state) {
            state.deleteIn(['sections', maybeParentChapterId, id]);
            state.deleteIn(['discussions', id]);
            state.deleteIn(['draftDiscussions', id]);
            if (state.hasIn(['chapters', maybeParentChapterId])) {
              return state.updateIn(['chapters', maybeParentChapterId, 'sections'], function(sections) {
                return sections.filterNot(function(sectionId) {
                  return sectionId === id;
                });
              });
            }
          }));
        }
      }
    } else if (id === this.state.getIn(['root', '_id'])) {
      return this.setState(this.state.set('root', Immutable.fromJS(doc)));
    } else if (doc.type === DOCUMENT_SECTIONS_V2_CHAPTER_TYPE) {
      return this.setState(this.state.setIn(['chapters', id], Immutable.fromJS(doc)));
    } else if (doc.type === DOCUMENT_SECTIONS_V2_SECTION_TYPE) {
      return this.setState(this.state.withMutations(function(state) {
        var sectionObjs, _ref4, _ref5;
        state.setIn(['sections', doc.parent, id], Immutable.fromJS(doc));
        if (((doc != null ? doc.mdaTableId : void 0) == null) && state.hasIn(['discussions', id])) {
          state.setIn(['discussions', id], Immutable.fromJS({
            annotations: (_ref4 = doc.content) != null ? _ref4.annotations : void 0,
            changeTracker: (_ref5 = doc.content) != null ? _ref5.changeTracker : void 0
          }));
        }
        if (doc.mdaTableId) {
          sectionObjs = Immutable.fromJS([
            {
              chapterId: doc.parent,
              mdaTableId: doc.mdaTableId,
              sectionId: id
            }
          ]);
          return state.setIn(['mdaTablesSections', doc.mdaTableId], sectionObjs);
        }
      }));
    } else if (doc.docType === MDA_TABLE_DOC_TYPE) {
      return this.setState(this.state.withMutations(function(state) {
        var sectionIds, sectionObjs;
        state.setIn(['mdaTables', id], Immutable.fromJS(doc));
        if (state.hasIn(['mdaTablesSections', id])) {
          sectionObjs = state.getIn(['mdaTablesSections', id]) || Immutable.List();
          sectionIds = sectionObjs.map(function(sectionObj) {
            return sectionObj.get('sectionId');
          });
          if (!sectionIds.isEmpty()) {
            return sectionIds.forEach(function(sectionId) {
              var _ref4, _ref5;
              return state.setIn(['discussions', sectionId], Immutable.fromJS({
                annotations: (_ref4 = (_ref5 = doc.content) != null ? _ref5.annotations : void 0) != null ? _ref4 : {},
                changeTracker: {},
                mdaTableId: id
              }));
            });
          }
        }
      }));
    }
  };

  DocumentSectionsV2Store.prototype.onSetActiveSidebarTab = function(tabId) {
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['_meta', 'activeSidebarTab'], tabId);
      return s.setIn(['_meta', 'discussionsFilters', 'status'], 'open');
    }));
  };

  DocumentSectionsV2Store.prototype.onUpdateDraftDiscussions = function(_arg) {
    var data, immData, sectionId, type;
    sectionId = _arg.sectionId, type = _arg.type, data = _arg.data;
    immData = Immutable.fromJS(data);
    return this.setState(this.state.withMutations(function(state) {
      var draftDiscussionsRecord, typePath;
      if (state.hasIn(['draftDiscussions', sectionId])) {
        typePath = type === 'comments' ? ['annotations'] : ['changeTracker', 'changes'];
        return state.setIn(['draftDiscussions', sectionId].concat(__slice.call(typePath)), immData);
      } else {
        draftDiscussionsRecord = Immutable.fromJS({
          annotations: type === 'comments' ? immData : state.getIn(['discussions', sectionId, 'annotations'], Immutable.Map()),
          changeTracker: type === 'suggestions' ? Immutable.Map({
            changes: immData
          }) : state.getIn(['discussions', sectionId, 'changeTracker'], Immutable.fromJS({
            changes: {}
          }))
        });
        return state.setIn(['draftDiscussions', sectionId], draftDiscussionsRecord);
      }
    }));
  };

  DocumentSectionsV2Store.prototype.onEditDiscussion = function(_arg) {
    var id, isNew;
    id = _arg.id, isNew = _arg.isNew;
    return this.setState(this.state.withMutations(function(state) {
      if (isNew) {
        state.setIn(['_meta', 'activeSidebarTab'], 'COMMENTS');
        state.setIn(['_meta', 'activeDiscussionItem'], id);
      }
      return state.setIn(['_meta', 'editingDiscussionItems', id], isNew ? {
        isNew: true
      } : true);
    }));
  };

  DocumentSectionsV2Store.prototype.onCloseDiscussionEdit = function(discussionId) {
    return this.setState(this.state.setIn(['_meta', 'editingDiscussionItems', discussionId], false));
  };

  DocumentSectionsV2Store.prototype.onSetActiveDiscussionItem = function(maybeData) {
    var id, scrollToContent;
    if (maybeData) {
      id = maybeData.id, scrollToContent = maybeData.scrollToContent;
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['_meta', 'activeSidebarTab'], _.isArray(id) ? 'COMMENTS' : 'SUGGESTIONS');
        state.setIn(['_meta', 'activeDiscussionItem'], id);
        if (scrollToContent) {
          return state.setIn(['_meta', 'scrollToSectionContent'], scrollToContent);
        }
      }));
    } else {
      return this.setState(this.state.setIn(['_meta', 'activeDiscussionItem'], null));
    }
  };

  DocumentSectionsV2Store.prototype.onSetScrollToSectionContent = function(maybeData) {
    var contentPos, sectionId;
    if (maybeData) {
      sectionId = maybeData.sectionId, contentPos = maybeData.contentPos;
      return this.setState(this.state.setIn(['_meta', 'scrollToSectionContent'], {
        sectionId: sectionId,
        contentPos: contentPos
      }));
    } else {
      return this.setState(this.state.setIn(['_meta', 'scrollToSectionContent'], null));
    }
  };

  DocumentSectionsV2Store.prototype.onSetScrollToSection = function(maybeData) {
    return this.setState(this.state.setIn(['_meta', 'scrollToSection'], (maybeData != null ? maybeData.sectionId : void 0) || null));
  };

  DocumentSectionsV2Store.prototype.applyDraftChangeDecision = function(_arg) {
    var accepted, changeId, sectionId;
    sectionId = _arg.sectionId, changeId = _arg.changeId, accepted = _arg.accepted;
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'acceptedOrRejectedChange'], {
        sectionId: sectionId,
        changeId: changeId,
        accepted: accepted
      });
      state.deleteIn(['discussions', sectionId, 'changeTracker', 'changes', changeId]);
      return state.deleteIn(['draftDiscussions', sectionId, 'changeTracker', 'changes', changeId]);
    }));
  };

  DocumentSectionsV2Store.prototype._getDiscussionPath = function(sectionId, type, discussionId) {
    var discussionsPath, draftDiscussionsPath, typePath;
    typePath = type === 'comments' ? ['annotations'] : ['changeTracker', 'changes'];
    discussionsPath = ['discussions', sectionId].concat(__slice.call(typePath), [discussionId]);
    draftDiscussionsPath = ['draftDiscussions', sectionId].concat(__slice.call(typePath), [discussionId]);
    if (this.state.hasIn(discussionsPath)) {
      return discussionsPath;
    }
    if (this.state.hasIn(draftDiscussionsPath)) {
      return draftDiscussionsPath;
    }
  };

  DocumentSectionsV2Store.prototype.onDeleteDiscussion = function(_arg) {
    var discussionPath, discussionType, id, sectionId;
    id = _arg.id, sectionId = _arg.sectionId, discussionType = _arg.discussionType;
    discussionPath = this._getDiscussionPath(sectionId, discussionType, id);
    return discussionPath && this.setState(this.state.deleteIn(discussionPath));
  };

  DocumentSectionsV2Store.prototype.onResolveDiscussion = function(_arg) {
    var discussionPath, discussionType, id, sectionId;
    id = _arg.id, sectionId = _arg.sectionId, discussionType = _arg.discussionType;
    discussionPath = this._getDiscussionPath(sectionId, discussionType, id);
    return discussionPath && this.setState(this.state.setIn(discussionPath.concat('resolved'), true));
  };

  DocumentSectionsV2Store.prototype.onSaveDiscussionItem = function(_arg) {
    var discussionItemPath, discussionType, id, sectionId, typePath, updatedData;
    id = _arg.id, sectionId = _arg.sectionId, discussionType = _arg.discussionType, updatedData = _arg.updatedData;
    typePath = discussionType === 'comments' ? ['annotations'] : ['changeTracker', 'changes'];
    discussionItemPath = ['discussions', sectionId].concat(__slice.call(typePath), [id]);
    return this.setState(this.state.updateIn(discussionItemPath, function(item) {
      return item.merge(updatedData);
    }));
  };

  DocumentSectionsV2Store.prototype.onRemoveDiscussionItem = function(_arg) {
    var discussionItemPath, discussionType, id, sectionId, typePath;
    id = _arg.id, sectionId = _arg.sectionId, discussionType = _arg.discussionType;
    typePath = discussionType === 'comments' ? ['annotations'] : ['changeTracker', 'changes'];
    discussionItemPath = ['discussions', sectionId].concat(__slice.call(typePath), [id]);
    return this.setState(this.state.deleteIn(discussionItemPath));
  };

  DocumentSectionsV2Store.prototype.onSaveChangeDecision = function(_arg) {
    var changeId, discussionItemPath, sectionId;
    sectionId = _arg.sectionId, changeId = _arg.changeId;
    discussionItemPath = [sectionId, 'changeTracker', 'changes', changeId];
    return this.setState(this.state.withMutations(function(state) {
      state.deleteIn(['discussions'].concat(__slice.call(discussionItemPath)));
      return state.deleteIn(['draftDiscussions'].concat(__slice.call(discussionItemPath)));
    }));
  };

  DocumentSectionsV2Store.prototype.onResolveSuggestions = function(_arg) {
    var sectionIds;
    sectionIds = _arg.sectionIds;
    if (_.isEmpty(sectionIds)) {
      return;
    }
    return this.setState(this.state.withMutations(function(state) {
      return sectionIds.forEach(function(sectionId) {
        state.setIn(['discussions', sectionId, 'changeTracker', 'changes'], Immutable.Map());
        if (state.hasIn(['draftDiscussions', sectionId, 'changeTracker', 'changes'])) {
          return state.setIn(['draftDiscussions', sectionId, 'changeTracker', 'changes'], Immutable.Map());
        }
      });
    }));
  };

  DocumentSectionsV2Store.prototype.onUpdateDraftDiscussion = function(_arg) {
    var discussionItemPath, discussionType, id, sectionId, typePath, updatedData;
    id = _arg.id, sectionId = _arg.sectionId, discussionType = _arg.discussionType, updatedData = _arg.updatedData;
    typePath = discussionType === 'comments' ? ['annotations'] : ['changeTracker', 'changes'];
    discussionItemPath = ['draftDiscussions', sectionId].concat(__slice.call(typePath), [id]);
    return this.setState(this.state.withMutations(function(state) {
      state.updateIn(discussionItemPath, function(currentData) {
        return currentData.merge(updatedData);
      });
      return state.setIn(['_meta', 'updatedDraftDiscussion'], {
        id: id,
        sectionId: sectionId,
        discussionType: discussionType,
        updatedData: updatedData
      });
    }));
  };

  DocumentSectionsV2Store.prototype.onRemoveDraftDiscussion = function(_arg) {
    var discussionItemPath, discussionType, id, item, sectionId, typePath;
    item = _arg.item, sectionId = _arg.sectionId, discussionType = _arg.discussionType;
    id = item.id;
    typePath = discussionType === 'comments' ? ['annotations'] : ['changeTracker', 'changes'];
    discussionItemPath = ['draftDiscussions', sectionId].concat(__slice.call(typePath), [id]);
    return this.setState(this.state.withMutations(function(state) {
      state.deleteIn(discussionItemPath);
      return state.setIn(['_meta', 'updatedDraftDiscussion'], {
        id: id,
        sectionId: sectionId,
        discussionType: discussionType,
        removed: true
      });
    }));
  };

  DocumentSectionsV2Store.prototype.onSectionEditStarted = function(sectionId) {
    var sectionDiscussions;
    sectionDiscussions = this.state.getIn(['discussions', sectionId]);
    if (sectionDiscussions) {
      return this.setState(this.state.setIn(['draftDiscussions', sectionId], sectionDiscussions));
    }
  };

  DocumentSectionsV2Store.prototype.onSetDiscussionsFilters = function(_arg) {
    var filterKey, filterValue;
    filterKey = _arg.filterKey, filterValue = _arg.filterValue;
    return this.setState(this.state.updateIn(['_meta', 'discussionsFilters'], function(filters) {
      return filters.set(filterKey, filterValue);
    }));
  };

  DocumentSectionsV2Store.prototype.onReopenDiscussion = function(_arg) {
    var discussionPath, discussionType, id, sectionId;
    id = _arg.id, sectionId = _arg.sectionId, discussionType = _arg.discussionType;
    discussionPath = this._getDiscussionPath(sectionId, discussionType, id);
    return discussionPath && this.setState(this.state.setIn(discussionPath.concat('resolved'), false));
  };

  DocumentSectionsV2Store.prototype.onSaveChapterContent = function(_arg) {
    var chapterId, content, projectId, sectionId;
    projectId = _arg.projectId, chapterId = _arg.chapterId, sectionId = _arg.sectionId, content = _arg.content;
    return this.setState(this.state.withMutations(function(state) {
      var draftDiscussions;
      state.setIn(['sections', sectionId, 'content'], content);
      draftDiscussions = state.getIn(['draftDiscussions', sectionId]);
      if (draftDiscussions) {
        state.setIn(['discussions', sectionId], draftDiscussions);
        return state.deleteIn(['draftDiscussions', sectionId]);
      }
    }));
  };

  DocumentSectionsV2Store.prototype.onSetSuggesting = function(suggesting) {
    return this.setState(this.state.setIn(['_meta', 'suggesting'], suggesting));
  };

  DocumentSectionsV2Store.prototype.onFlushDraftDiscussions = function(_arg) {
    var sectionId;
    sectionId = _arg.sectionId;
    return this.setState(this.state.deleteIn(['draftDiscussions', sectionId]));
  };

  DocumentSectionsV2Store.prototype.onResetUpdatedDraftDiscussionData = function() {
    return this.setState(this.state.setIn(['_meta', 'updatedDraftDiscussion'], null));
  };

  DocumentSectionsV2Store.prototype.resetAcceptedOrRejectedChange = function() {
    return this.setState(this.state.setIn(['_meta', 'acceptedOrRejectedChange'], null));
  };

  DocumentSectionsV2Store.prototype.onSetBatchEditorOptions = function(params) {
    var accept, chapterId, type, _ref3;
    _ref3 = params || {}, chapterId = _ref3.chapterId, accept = _ref3.accept, type = _ref3.type;
    return this.setState(this.state.setIn(['_meta', 'batchEditorOptions'], Immutable.Map(params ? {
      modalOpen: true,
      chapterId: chapterId,
      accept: accept,
      type: type
    } : {})));
  };

  DocumentSectionsV2Store.prototype.onCloseBatchEditorOptionsModal = function() {
    this.setState(this.state.setIn(['_meta', 'batchEditorOptions', 'modalOpen'], false));
    return _.delay((function(_this) {
      return function() {
        return _this.setState(_this.state.setIn(['_meta', 'batchEditorOptions'], Immutable.Map({})));
      };
    })(this), 500);
  };

  DocumentSectionsV2Store.prototype.getBatchEditorOptions = function() {
    return this.state.getIn(['_meta', 'batchEditorOptions'], Immutable.Map());
  };

  DocumentSectionsV2Store.prototype.getRoot = function() {
    return this.state.get('root');
  };

  DocumentSectionsV2Store.prototype.getChapters = function() {
    return this.state.get('chapters');
  };

  DocumentSectionsV2Store.prototype.getChapter = function(chapterId) {
    return this.state.getIn(['chapters', chapterId]);
  };

  DocumentSectionsV2Store.prototype.getChapterSections = function(chapterId) {
    return this.state.getIn(['sections', chapterId]);
  };

  DocumentSectionsV2Store.prototype.getChapterSection = function(chapterId, sectionId) {
    return this.state.getIn(['sections', chapterId, sectionId]);
  };

  DocumentSectionsV2Store.prototype.getRecommendations = function() {
    return this.state.get('recommendations');
  };

  DocumentSectionsV2Store.prototype.getMdaTables = function() {
    return this.state.get('mdaTables');
  };

  DocumentSectionsV2Store.prototype.isFetching = function() {
    return this.state.getIn(['_meta', 'fetching']);
  };

  DocumentSectionsV2Store.prototype.isFetchingSectionsForSearch = function() {
    return this.state.getIn(['_meta', 'fetchingSectionsForSearch']);
  };

  DocumentSectionsV2Store.prototype.isFetchingChapterSections = function(chapterId) {
    return Boolean(this.state.getIn(['_meta', 'fetchingChapters', chapterId]));
  };

  DocumentSectionsV2Store.prototype.isChapterExpanded = function(chapterId) {
    return Boolean(this.state.getIn(['_meta', 'expandedChapters', chapterId]));
  };

  DocumentSectionsV2Store.prototype.getResultingDiscussions = function() {
    var activeSidebarTab, discussions, discussionsFilterVisibleFor, discussionsFiltersStatus, draftDiscussions, merged, rootChaptersOrder, sectionsOrder;
    discussions = this.state.get('discussions');
    draftDiscussions = this.state.get('draftDiscussions');
    merged = discussions.merge(draftDiscussions);
    rootChaptersOrder = this.state.getIn(['root', 'chaptersOrder'], Immutable.List());
    sectionsOrder = this.getSectionsOrder(rootChaptersOrder).filter(function(sectionId) {
      return merged.has(sectionId);
    });
    discussionsFiltersStatus = this.state.getIn(['_meta', 'discussionsFilters', 'status']);
    discussionsFilterVisibleFor = this.state.getIn(['_meta', 'discussionsFilters', 'visibleFor']);
    activeSidebarTab = this.getActiveSidebarTab();
    if (activeSidebarTab !== 'SUGGESTIONS' && activeSidebarTab !== 'COMMENTS') {
      return Immutable.List();
    }
    return sectionsOrder.reduce(function(acc, sectionId) {
      var sectionDiscussions;
      sectionDiscussions = merged.get(sectionId);
      return acc.withMutations(function(discussionItems) {
        var annotations, changes, mdaTableId;
        annotations = sectionDiscussions.get('annotations');
        changes = sectionDiscussions.getIn(['changeTracker', 'changes'], Immutable.Map());
        mdaTableId = sectionDiscussions.get('mdaTableId');
        if (activeSidebarTab === 'COMMENTS') {
          annotations.valueSeq().sortBy(function(ann) {
            return ann.get('from');
          }).forEach(function(annotation) {
            var ann;
            if (annotation.get('parentId')) {
              return;
            }
            ann = prepareAnnotation(annotation, annotations, discussionsFiltersStatus, discussionsFilterVisibleFor, draftDiscussions, sectionId, mdaTableId);
            if (ann) {
              return discussionItems.push(ann);
            }
          });
        }
        if (activeSidebarTab === 'SUGGESTIONS') {
          return changes.valueSeq().forEach(function(change) {
            var id, isDraft;
            id = change.get('id');
            isDraft = draftDiscussions.hasIn([sectionId, 'changeTracker', 'changes', id]);
            if (discussionsFiltersStatus === 'resolved') {
              return;
            }
            return discussionItems.push(Immutable.Map({
              id: id,
              isDraft: isDraft,
              sectionId: sectionId,
              mdaTableId: mdaTableId,
              type: 'suggestions',
              data: change
            }));
          });
        }
      });
    }, Immutable.List());
  };

  DocumentSectionsV2Store.prototype.getActiveSidebarTab = function() {
    return this.state.getIn(['_meta', 'activeSidebarTab']);
  };

  DocumentSectionsV2Store.prototype.getActiveDiscussionItem = function() {
    return this.state.getIn(['_meta', 'activeDiscussionItem']);
  };

  DocumentSectionsV2Store.prototype.getDiscussionsFilters = function() {
    return this.state.getIn(['_meta', 'discussionsFilters'], Immutable.Map());
  };

  DocumentSectionsV2Store.prototype.getScrollToChapter = function() {
    return this.state.getIn(['_meta', 'scrollToChapter']);
  };

  DocumentSectionsV2Store.prototype.getScrollToSection = function() {
    return this.state.getIn(['_meta', 'scrollToSection']);
  };

  DocumentSectionsV2Store.prototype.getSuggesting = function() {
    var isMdgOn;
    isMdgOn = isActiveWorkspaceOrFeatureSwitchOn(MDG_WORKSPACE, 'mdgDocSecFeatures');
    return mediator.user.hasRole(['mdg_panel_reviewer']) || this.state.getIn(['_meta', 'suggesting'], isMdgOn && mediator.user.hasRole('researcher') || false);
  };

  DocumentSectionsV2Store.prototype.getSectionsOrder = function(rootOrder) {
    var sectionsOrder;
    sectionsOrder = rootOrder.reduce((function(_this) {
      return function(acc, chapterId) {
        var subchapters;
        subchapters = _this.state.getIn(['chapters', chapterId, 'subchapters'], Immutable.List());
        acc = acc.concat(_this.state.getIn(['chapters', chapterId, 'sections']));
        if (subchapters.isEmpty()) {
          return acc;
        }
        return acc.concat(_this.getSectionsOrder(subchapters));
      };
    })(this), Immutable.List());
    return sectionsOrder.flatten();
  };

  DocumentSectionsV2Store.prototype.getDraftDiscussions = function(sectionIds) {
    return sectionIds.reduce((function(_this) {
      return function(draftDiscussions, sectionId) {
        var maybeDraftDiscussions;
        maybeDraftDiscussions = _this.state.getIn(['draftDiscussions', sectionId]);
        if (maybeDraftDiscussions) {
          return draftDiscussions.set(sectionId, maybeDraftDiscussions);
        } else {
          return draftDiscussions;
        }
      };
    })(this), Immutable.Map());
  };

  DocumentSectionsV2Store.prototype.getSectionsForSearch = function() {
    return this.state.getIn(['_meta', 'sectionsForSearch'], Immutable.Map());
  };

  DocumentSectionsV2Store.prototype.getSectionBySectionType = function(sectionType) {
    return this.state.get('sections', Immutable.Map()).flatten(1).find(function(section) {
      return section.get('sectionType') === sectionType;
    }, null, Immutable.Map());
  };

  DocumentSectionsV2Store.prototype.getUpdatedDraftDiscussion = function() {
    return this.state.getIn(['_meta', 'updatedDraftDiscussion']);
  };

  DocumentSectionsV2Store.prototype.getAcceptedOrRejectedChange = function() {
    return this.state.getIn(['_meta', 'acceptedOrRejectedChange']);
  };

  DocumentSectionsV2Store.prototype.getEditingDiscussionItems = function() {
    return this.state.getIn(['_meta', 'editingDiscussionItems']);
  };

  DocumentSectionsV2Store.prototype.getEditingDiscussionReplies = function() {
    return this.state.getIn(['_meta', 'editingDiscussionReplies']);
  };

  DocumentSectionsV2Store.prototype.getScrollToSectionContent = function() {
    return this.state.getIn(['_meta', 'scrollToSectionContent']);
  };

  DocumentSectionsV2Store.prototype.findSectionChapter = function(sectionId) {
    return this.getChapters().find(function(chapter) {
      return chapter.get('sections').includes(sectionId);
    });
  };

  DocumentSectionsV2Store.prototype.getSections = function() {
    return this.state.get('sections');
  };

  DocumentSectionsV2Store.prototype.getSection = function(chapterId, sectionId) {
    return this.state.getIn(['sections', chapterId, sectionId], Immutable.Map());
  };

  DocumentSectionsV2Store.prototype.getRootChaptersOrder = function() {
    return this.state.getIn(['root', 'chaptersOrder'], Immutable.List());
  };

  DocumentSectionsV2Store.prototype.getAlreadyUsedMdaTablesIds = function() {
    return this.state.get('mdaTablesSections').reduce(function(acc, entries) {
      return entries.reduce(function(acc2, entry) {
        return acc2.add(entry.get('mdaTableId'));
      }, acc);
    }, Immutable.Set());
  };

  DocumentSectionsV2Store.prototype.getMdaTopicId = function() {
    return this.state.get('mdaTopicId');
  };

  return DocumentSectionsV2Store;

})();

module.exports = alt.createStore(immutable(DocumentSectionsV2Store), 'DocumentSectionsV2Store');
