var Rm5ExportTableCell, rm5ExportConfig, rm5TbodyCells, _getCellFootnotes;

rm5ExportConfig = require('lib/rm5_export_config');

rm5TbodyCells = rm5ExportConfig.tbodyCells;

Rm5ExportTableCell = rm5ExportConfig.Rm5ExportTableCell;

_getCellFootnotes = function(cell, footnotes) {
  var attribute, cellFootnotes, cellPossibleAttributes, _i, _len;
  cellPossibleAttributes = rm5ExportConfig.attributesMap[cell];
  cellFootnotes = [];
  for (_i = 0, _len = cellPossibleAttributes.length; _i < _len; _i++) {
    attribute = cellPossibleAttributes[_i];
    if (attribute in footnotes) {
      cellFootnotes = cellFootnotes.concat(footnotes[attribute]);
    }
  }
  cellFootnotes.sort(function(a, b) {
    return a - b;
  });
  if (!_(cellFootnotes).isEmpty()) {
    return _(cellFootnotes).unique().join(' ');
  } else {
    return '';
  }
};

module.exports = function(attributes) {
  var cellName, cellNotes, correspondingRiskNotes, formatter, outcomeHasFootnotes, outcomeRow, placeholder, prefix, rowSchema, sofControlNotes, tableType, tableView, _ref;
  tableView = attributes.tableView;
  tableType = "" + tableView + "_regular";
  rowSchema = rm5ExportConfig.theadSchemas[tableType][0];
  outcomeRow = {
    cells: []
  };
  outcomeHasFootnotes = !_(attributes.footnotes).isEmpty();
  for (cellName in rowSchema) {
    placeholder = (_ref = cellName.match(/\[[a-z]+\]/)) != null ? _ref[0] : void 0;
    cellName = cellName.replace(placeholder, '');
    if (cellName === 'absoluteEffect') {
      if (outcomeHasFootnotes) {
        sofControlNotes = _getCellFootnotes('contSofControl', attributes.footnotes);
        correspondingRiskNotes = _getCellFootnotes('contCorrespondingRisk', attributes.footnotes);
      }
      outcomeRow.cells.push(new Rm5ExportTableCell({
        textContent: rm5TbodyCells['contSofControl'](attributes),
        sup: sofControlNotes || '',
        name: 'contSofControl'
      }));
      switch (tableView) {
        case 'sof':
          outcomeRow.cells.push(new Rm5ExportTableCell({
            textContent: rm5TbodyCells['contRiskDifference'](attributes),
            sup: correspondingRiskNotes || '',
            name: 'contRiskDifference'
          }));
          break;
        case 'sof_accp':
          outcomeRow.cells.push(new Rm5ExportTableCell({
            textContent: rm5TbodyCells['contRiskDifference'](attributes),
            sup: correspondingRiskNotes || '',
            name: 'contRiskDifference'
          }));
          break;
        case 'sof_v3':
        case 'sof_v4':
          outcomeRow.cells.push(new Rm5ExportTableCell({
            textContent: rm5TbodyCells['contCorrespondingRisk'](attributes),
            sup: correspondingRiskNotes || '',
            name: 'contCorrespondingRisk'
          }));
          outcomeRow.cells.push(new Rm5ExportTableCell({
            textContent: rm5TbodyCells['contRiskDifference'](attributes),
            sup: correspondingRiskNotes || '',
            name: 'contRiskDifference'
          }));
      }
    } else if ((cellName === 'sofControl' || cellName === 'correspondingRisk' || cellName === 'riskDifference') && tableView === 'sof_short') {
      prefix = 'cont';
      formatter = prefix + _.string.capitalize(cellName);
      outcomeRow.cells.push(new Rm5ExportTableCell({
        textContent: rm5TbodyCells[formatter](attributes),
        sup: sofControlNotes || '',
        name: formatter
      }));
    } else {
      if (outcomeHasFootnotes) {
        cellNotes = _getCellFootnotes(cellName, attributes.footnotes);
      }
      outcomeRow.cells.push(new Rm5ExportTableCell({
        textContent: rm5TbodyCells[cellName](attributes),
        sup: cellNotes || '',
        name: cellName
      }));
    }
  }
  return outcomeRow;
};
