var PMState, getScrollableParent, getSelectedTextNodes, getSerializedState, insertReference, removeHighlightMarks, resetSelection, resetSelectionOnState, scrollIntoMiddle, scrollIntoView, scrollPosIntoView;

PMState = Epiditor.PMState;

getScrollableParent = function($el) {
  var $parent, parentStyles;
  $parent = $el.parentElement;
  if ($parent === null) {
    return null;
  }
  if ($parent.scrollHeight > $parent.clientHeight) {
    parentStyles = getComputedStyle($parent);
    if (parentStyles.overflow !== 'visible') {
      return $parent;
    } else {
      return getScrollableParent($parent);
    }
  }
  return getScrollableParent($parent);
};

scrollIntoMiddle = function($el) {
  var $parent, deltaScroll, elTop, parentHeight, parentTop, _ref;
  $parent = getScrollableParent($el);
  if ($parent === null) {
    return;
  }
  _ref = $parent.getBoundingClientRect(), parentTop = _ref.top, parentHeight = _ref.height;
  elTop = $el.getBoundingClientRect().top;
  deltaScroll = elTop - (parentTop + parentHeight / 2);
  return $parent.scrollTop += deltaScroll;
};

scrollIntoView = function($el) {
  var $parent, elTop, parentBottom, parentTop, _ref;
  $parent = getScrollableParent($el);
  if ($parent === null) {
    return;
  }
  _ref = $parent.getBoundingClientRect(), parentTop = _ref.top, parentBottom = _ref.bottom;
  elTop = $el.getBoundingClientRect().top;
  if (elTop < parentTop || elTop > parentBottom) {
    return scrollIntoMiddle($el);
  }
};

getSelectedTextNodes = function(state) {
  var from, selectionTextNodes, to, _ref;
  _ref = state.selection, from = _ref.from, to = _ref.to;
  selectionTextNodes = [];
  state.doc.nodesBetween(from, to, function(node) {
    if (node.isText) {
      return selectionTextNodes.push(node);
    }
  });
  return selectionTextNodes;
};

getSerializedState = function(editorView) {
  var serialized;
  serialized = editorView.state.toJSON();
  if (R.isNil(serialized.selection.anchor)) {
    serialized.selection.anchor = 1;
  }
  if (R.isNil(serialized.selection.head)) {
    serialized.selection.head = 1;
  }
  return serialized;
};

scrollPosIntoView = function(pos, editorView, setSelection) {
  var maybeEl, selection, state;
  if (setSelection == null) {
    setSelection = false;
  }
  if (setSelection) {
    state = editorView.state;
    selection = PMState.Selection.near(state.doc.resolve(pos));
    editorView.dispatch(state.tr.setSelection(selection));
  }
  maybeEl = editorView.domAtPos(pos, -1).node;
  if (R.isNil(maybeEl)) {
    return;
  }
  return scrollIntoView(maybeEl instanceof Text ? maybeEl.parentElement : maybeEl);
};

resetSelectionOnState = function(editorState) {
  var selection;
  selection = PMState.TextSelection.create(editorState.doc, 0);
  editorState.tr.setSelection(selection);
  return editorState;
};

resetSelection = function(editorView) {
  var selection, state;
  state = editorView.state;
  selection = PMState.TextSelection.create(state.doc, 0);
  return editorView.dispatch(state.tr.setSelection(selection));
};

insertReference = function(state, dispatch) {
  return function(referenceData) {
    var $from, index, reference, schema, selection, tr;
    tr = state.tr, selection = state.selection, schema = state.schema;
    reference = schema.nodes.reference;
    if (!reference) {
      return false;
    }
    $from = selection.$from;
    index = $from.index();
    if (!$from.parent.canReplaceWith(index, index, reference)) {
      return false;
    }
    if (dispatch) {
      dispatch(tr.replaceSelectionWith(reference.create(referenceData)));
    }
    return true;
  };
};

removeHighlightMarks = function(content) {
  return content != null ? content.map(function(c) {
    if (c.type === 'text') {
      return _.extend({}, c, {
        marks: (c.marks || []).filter(function(m) {
          return m.type !== 'highlight';
        })
      });
    } else {
      return _.extend({}, c, {
        content: removeHighlightMarks(c.content)
      });
    }
  }) : void 0;
};

module.exports = {
  getSelectedTextNodes: getSelectedTextNodes,
  getSerializedState: getSerializedState,
  insertReference: insertReference,
  resetSelection: resetSelection,
  resetSelectionOnState: resetSelectionOnState,
  scrollIntoView: scrollIntoView,
  scrollPosIntoView: scrollPosIntoView,
  removeHighlightMarks: removeHighlightMarks
};
