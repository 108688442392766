var EditView, Outcome, outcomeCellEditViewTypes, qualityFootnotesAttr;

EditView = require('base/views/edit_view');

Outcome = require('models/outcome');

qualityFootnotesAttr = ['riskOfBias', 'inconsistency', 'indirectness', 'imprecision', 'publicationBias', 'largeEffect', 'plausibleConfounding', 'doseResponseGradient'];

outcomeCellEditViewTypes = {
  'designStudies': {
    editType: EditView.TYPES.COMPOUND,
    cellFormatter: 'designStudies',
    formatterAttrsObserve: ['designStudies', 'robinsIEnabled']
  },
  'importance': {
    editType: EditView.TYPES.SELECTION,
    cellFormatter: 'importanceLabel',
    formatterAttrsObserve: ['importanceLabel']
  },
  'riskOfBias': {
    editType: EditView.TYPES.SELECTION,
    cellFormatter: 'qualityModifier'
  },
  'inconsistency': {
    editType: EditView.TYPES.SELECTION,
    cellFormatter: 'qualityModifier'
  },
  'indirectness': {
    editType: EditView.TYPES.SELECTION,
    cellFormatter: 'qualityModifier'
  },
  'imprecision': {
    editType: EditView.TYPES.SELECTION,
    cellFormatter: 'qualityModifier'
  },
  'publicationBias': {
    editType: EditView.TYPES.SELECTION,
    cellFormatter: 'publicationBias'
  },
  'noOfStudies': {
    editType: EditView.TYPES.COMPOUND
  },
  'otherConsiderations': {
    editType: EditView.TYPES.COMPOUND,
    cellFormatter: 'otherConsiderations',
    formatterAttrsObserve: ['publicationBias', 'largeEffect', 'plausibleConfounding', 'doseResponseGradient']
  },
  'intervention': {
    editType: EditView.TYPES.COMPOUND,
    cellFormatter: 'intervention',
    formatterAttrsObserve: ['type', 'interventionCount', 'interventionTotal', 'effectMeasure'],
    footnotesAttrs: {
      common: ['interventionCount', 'interventionTotal']
    }
  },
  'control': {
    editType: EditView.TYPES.COMPOUND,
    otherFormatters: [
      {
        contentDefaultClass: 'low-value',
        cellFormatter: 'lowControlRisk',
        formatterAttrsObserve: ['type', 'lowControlRisk', 'lowControlLabel', 'absDenominator'],
        footnotesAttrs: {
          common: ['lowControlRisk']
        }
      }, {
        contentDefaultClass: 'moderate-value',
        cellFormatter: 'moderateControlRisk',
        formatterAttrsObserve: ['type', 'moderateControlRisk', 'moderateControlLabel', 'absDenominator'],
        footnotesAttrs: {
          common: ['moderateControlRisk']
        }
      }, {
        contentDefaultClass: 'high-value',
        cellFormatter: 'highControlRisk',
        formatterAttrsObserve: ['type', 'highControlRisk', 'highControlLabel', 'absDenominator'],
        footnotesAttrs: {
          common: ['highControlRisk']
        }
      }, {
        contentDefaultClass: 'calculated-value-label',
        cellFormatter: 'calculatedControlLabel',
        footnotesAttrs: {
          common: []
        }
      }, {
        contentDefaultClass: 'low-value-label',
        cellFormatter: 'lowControlLabel',
        formatterAttrsObserve: ['lowControlLabel'],
        footnotesAttrs: {
          common: ['lowControlLabel']
        }
      }, {
        contentDefaultClass: 'moderate-value-label',
        cellFormatter: 'moderateControlLabel',
        formatterAttrsObserve: ['moderateControlLabel'],
        footnotesAttrs: {
          common: ['moderateControlLabel']
        }
      }, {
        contentDefaultClass: 'high-value-label',
        cellFormatter: 'highControlLabel',
        formatterAttrsObserve: ['highControlLabel'],
        footnotesAttrs: {
          common: ['highControlLabel']
        }
      }, {
        contentDefaultClass: 'main-value',
        cellFormatter: 'control',
        formatterAttrsGetOnly: ['question.intervention'],
        formatterAttrsObserve: ['type', 'name', 'changeScoreInControls', 'effectMeasure', 'changeScoreInControlsValue', 'units', 'controlCount', 'controlTotal', 'absDenominator', 'showFinalScoreInControls'],
        footnotesAttrs: {
          common: ['controlCount', 'controlTotal', 'calculatedControl', 'calculatedControlValue', 'absDenominator', 'changeScoreInControls', 'changeScoreInControlsValue', 'units', 'showFinalScoreInControls']
        }
      }
    ]
  },
  'relativeEffect': {
    editType: EditView.TYPES.COMPOUND,
    cellFormatter: 'relativeEffect',
    formatterAttrsObserve: ['metaType', 'type', 'otherMeasure', 'effectMeasure', 'effectSize', 'confidenceIntervalFrom', 'confidenceIntervalTo', 'nonEvent', 'eventType', 'name'],
    footnotesAttrs: {
      common: ['otherMeasure', 'effectMeasure', 'effectSize', 'confidenceIntervalFrom', 'confidenceIntervalTo']
    }
  },
  'absoluteEffect': {
    editType: EditView.TYPES.COMPOUND,
    cellFormatter: 'absoluteEffect',
    otherFormatters: [
      {
        contentDefaultClass: 'main-value',
        cellFormatter: 'absoluteEffect',
        formatterAttrsObserve: ['type', 'absEffectAutoCalc', 'absEffectSize', 'absEffectFrom', 'absEffectTo', 'absDenominator', 'perPatients', 'perPatientsOther', 'perTimeFrame', 'perTimeFrameOther', 'effectSize', 'units', 'confidenceIntervalFrom', 'confidenceIntervalTo', 'effectMeasure', 'otherMeasure'],
        footnotesAttrs: {
          common: ['absEffectAutoCalc', 'absEffectSize', 'absEffectFrom', 'absEffectTo', 'absDenominator']
        }
      }, {
        contentDefaultClass: 'low-value',
        cellFormatter: 'absoluteEffectLow',
        formatterAttrsObserve: ['absEffectSizeLow', 'absEffectFromLow', 'absEffectToLow', 'absDenominator', 'effectSize', 'perPatients', 'perPatientsOther', 'effectMeasure', 'otherMeasure', 'absEffectAutoCalc', 'perPatients', 'perPatientsOther', 'perTimeFrame', 'perTimeFrameOther'],
        footnotesAttrs: {
          common: ['absEffectAutoCalcLow', 'absEffectSizeLow', 'absEffectFromLow', 'absEffectToLow', 'absDenominator']
        }
      }, {
        contentDefaultClass: 'moderate-value',
        cellFormatter: 'absoluteEffectModerate',
        formatterAttrsObserve: ['absEffectSizeModerate', 'absEffectFromModerate', 'absEffectToModerate', 'absDenominator', 'perPatients', 'perPatientsOther', 'confidenceIntervalTo', 'effectMeasure', 'otherMeasure', 'absEffectAutoCalc', 'perPatients', 'perPatientsOther', 'perTimeFrame', 'perTimeFrameOther'],
        footnotesAttrs: {
          common: ['absEffectAutoCalcModerate', 'absEffectSizeModerate', 'absEffectFromModerate', 'absEffectToModerate', 'absDenominator']
        }
      }, {
        contentDefaultClass: 'high-value',
        cellFormatter: 'absoluteEffectHigh',
        formatterAttrsObserve: ['absEffectSizeHigh', 'absEffectFromHigh', 'absEffectToHigh', 'absDenominator', 'perPatients', 'perPatientsOther', 'perTimeFrame', 'perTimeFrameOther', 'effectSize', 'confidenceIntervalFrom', 'confidenceIntervalTo', 'effectMeasure', 'otherMeasure', 'absEffectAutoCalc'],
        footnotesAttrs: {
          common: ['absEffectAutoCalcHigh', 'absEffectSizeHigh', 'absEffectFromHigh', 'absEffectToHigh', 'absDenominator']
        }
      }, {
        contentDefaultClass: 'cont-main-value',
        cellFormatter: 'absoluteEffect',
        formatterAttrsObserve: ['type', 'absEffectAutoCalc', 'absEffectSize', 'absEffectFrom', 'absEffectTo', 'absDenominator', 'perPatients', 'perPatientsOther', 'units', 'unitsType', 'perTimeFrame', 'perTimeFrameOther', 'effectSize', 'confidenceIntervalFrom', 'confidenceIntervalTo', 'effectMeasure', 'otherMeasure'],
        footnotesAttrs: {
          common: ['absEffectAutoCalc', 'absEffectSize', 'absEffectFrom', 'absEffectTo', 'absDenominator', 'otherMeasure', 'effectMeasure', 'effectSize', 'confidenceIntervalFrom', 'confidenceIntervalTo']
        }
      }
    ]
  },
  'comments': {
    editType: EditView.TYPES.COMPOUND,
    cellFormatter: 'comments'
  },
  'quality': {
    editType: EditView.TYPES.COMPOUND,
    cellFormatter: 'quality',
    cellCustomSelector: 'td.quality',
    formatterAttrsObserve: _(['quality']).union(Outcome.QUALITY_MODIFIERS),
    footnotesAttrs: {
      SOF: qualityFootnotesAttr,
      PrintoutSOF: qualityFootnotesAttr,
      SOF_ACCP: qualityFootnotesAttr,
      PrintoutSOFACCP: qualityFootnotesAttr,
      SOF_v3: qualityFootnotesAttr,
      PrintoutSOFv3: qualityFootnotesAttr,
      SOF_v4: qualityFootnotesAttr,
      PrintoutSOFv4: qualityFootnotesAttr,
      LayerOneSof: ['designStudies'].concat(qualityFootnotesAttr),
      LayerTwoSof: ['designStudies'].concat(qualityFootnotesAttr),
      PrintoutLayerOneSof: ['designStudies'].concat(qualityFootnotesAttr),
      PrintoutLayerTwoSof: ['designStudies'].concat(qualityFootnotesAttr),
      common: ['quality']
    }
  },
  'sofOutcomeLabel': {
    editType: EditView.TYPES.COMPOUND,
    cellFormatter: 'sofLabel',
    formatterAttrsObserve: ['name', 'shortName', 'designStudies', 'metaType', 'followUpType', 'followUpLength', 'followUpTimeUnit', 'followUpLengthTo', 'followUpToTimeUnit', 'othersFollowUpTimeUnit', 'othersFollowUpToTimeUnit', 'type', 'scaleFrom', 'scaleTo', 'measuredWith', 'timingOfExposure', 'scaleType'],
    footnotesAttrs: {
      common: ['name', 'shortName', 'othersFollowUpTimeUnit', 'measuredWith', 'timingOfExposure', 'scaleTo'],
      SOF: ['followUpType', 'followUpLength', 'followUpTimeUnit']
    }
  },
  'sofV3OutcomeLabel': {
    editType: EditView.TYPES.COMPOUND,
    cellFormatter: 'sofV3Label',
    formatterAttrsObserve: ['name', 'shortName', 'designStudies', 'measuredWith', 'followUpType', 'followUpLength', 'followUpTimeUnit', 'followUpLengthTo', 'followUpToTimeUnit', 'othersFollowUpTimeUnit', 'othersFollowUpToTimeUnit', 'interventionCount', 'controlCount', 'interventionTotal', 'controlTotal', 'noOfStudies', 'withOutcome', 'withoutOutcome', 'metaType'],
    footnotesAttrs: {
      common: ['name', 'shortName', 'measuredWith', 'followUpType', 'followUpLength', 'followUpTimeUnit', 'othersFollowUpTimeUnit', 'interventionTotal', 'controlTotal', 'noOfStudies', 'designStudies']
    }
  },
  'sofV4OutcomeLabel': {
    editType: EditView.TYPES.COMPOUND,
    cellFormatter: 'sofV4Label',
    formatterAttrsObserve: ['name', 'shortName', 'designStudies', 'measuredWith', 'followUpType', 'followUpLength', 'followUpTimeUnit', 'followUpLengthTo', 'followUpToTimeUnit', 'othersFollowUpTimeUnit', 'othersFollowUpToTimeUnit', 'metaType'],
    footnotesAttrs: {
      common: ['name', 'shortName', 'measuredWith', 'followUpType', 'followUpLength', 'followUpTimeUnit']
    }
  },
  'oneRowOutcomeLabel': {
    editType: EditView.TYPES.COMPOUND,
    cellFormatter: 'oneRowLabel',
    formatterAttrsObserve: ['name', 'shortName', 'measuredWith', 'followUpLength', 'type', 'metaType'],
    footnotesAttrs: {
      common: ['name', 'shortName', 'measuredWith', 'followUpLength', 'type', 'metaType']
    }
  },
  'diagnosticOutcomeLabel': {
    editType: EditView.TYPES.COMPOUND,
    cellFormatter: 'diagnosticLabel',
    formatterAttrsObserve: ['name'],
    formatterAttrsGetOnly: ['question.targetCondition']
  },
  'noOfStudiesPatients': {
    editType: EditView.TYPES.COMPOUND,
    cellFormatter: 'noOfStudiesPatients',
    formatterAttrsObserve: ['noOfStudies', 'noOfPatients']
  },
  'caseControl': {
    editType: EditView.TYPES.COMPOUND,
    cellFormatter: 'caseControl',
    formatterAttrsObserve: ['withOutcome', 'withoutOutcome', 'designStudies', 'interventionCount', 'interventionTotal', 'controlCount', 'controlTotal']
  },
  'noOfParticipantsStudies': {
    editType: EditView.TYPES.COMPOUND,
    cellFormatter: 'noOfParticipantsStudies',
    formatterAttrsObserve: ['interventionTotal', 'controlTotal', 'noOfStudies', 'designStudies', 'withOutcome', 'withoutOutcome', 'interventionCount', 'controlCount'],
    footnotesAttrs: {
      common: ['interventionTotal', 'controlTotal', 'noOfStudies', 'designStudies', 'withOutcome', 'withoutOutcome']
    }
  },
  'correspondingRisk': {
    editType: EditView.TYPES.COMPOUND,
    cellCustomSelector: 'td.corresponding-risk',
    otherFormatters: [
      {
        contentDefaultClass: 'low-value',
        cellFormatter: 'correspondingRiskLow',
        formatterAttrsObserve: ['lowControlRisk', 'effectSize', 'effectMeasure', 'confidenceIntervalFrom', 'confidenceIntervalTo', 'absDenominator', 'metaType', 'type', 'eventType', 'interventionCount', 'interventionTotal', 'absEffectAutoCalc', 'interventionConfidenceIntervalFrom', 'interventionConfidenceIntervalTo'],
        footnotesAttrs: {
          common: []
        }
      }, {
        contentDefaultClass: 'moderate-value',
        cellFormatter: 'correspondingRiskModerate',
        formatterAttrsObserve: ['moderateControlRisk', 'effectSize', 'effectMeasure', 'confidenceIntervalFrom', 'confidenceIntervalTo', 'absDenominator', 'metaType', 'type', 'eventType', 'interventionCount', 'interventionTotal', 'absEffectAutoCalc', 'interventionConfidenceIntervalFrom', 'interventionConfidenceIntervalTo'],
        footnotesAttrs: {
          common: []
        }
      }, {
        contentDefaultClass: 'high-value',
        cellFormatter: 'correspondingRiskHigh',
        formatterAttrsObserve: ['highControlRisk', 'effectSize', 'effectMeasure', 'confidenceIntervalFrom', 'confidenceIntervalTo', 'absDenominator', 'metaType', 'type', 'eventType', 'interventionCount', 'interventionTotal', 'absEffectAutoCalc', 'interventionConfidenceIntervalFrom', 'interventionConfidenceIntervalTo'],
        footnotesAttrs: {
          common: []
        }
      }, {
        contentDefaultClass: 'main-value',
        cellFormatter: 'correspondingRisk',
        footnotesAttrs: {
          common: ['interventionCount', 'interventionTotal']
        },
        formatterAttrsObserve: ['type', 'controlCount', 'controlTotal', 'effectSize', 'unitsType', 'effectMeasure', 'confidenceIntervalFrom', 'confidenceIntervalTo', 'absDenominator', 'units', 'otherMeasure', 'name', 'metaType', 'interventionCount', 'interventionTotal', 'absEffectAutoCalc', 'interventionConfidenceIntervalFrom', 'interventionConfidenceIntervalTo']
      }
    ]
  },
  'noOfParticipantsStudiesFollowUp': {
    editType: EditView.TYPES.COMPOUND,
    cellFormatter: 'noOfParticipantsStudiesFollowUp',
    formatterAttrsObserve: ['interventionTotal', 'controlTotal', 'noOfStudies', 'followUpType', 'followUpLength', 'followUpTimeUnit', 'followUpLengthTo', 'followUpToTimeUnit', 'othersFollowUpTimeUnit', 'othersFollowUpToTimeUnit', 'designStudies', 'interventionCount', 'controlCount', 'withOutcome', 'withoutOutcome']
  },
  'riskDifferenceWithIntervention': {
    editType: EditView.TYPES.COMPOUND,
    cellFormatter: 'riskDifference',
    otherFormatters: [
      {
        contentDefaultClass: 'low-value',
        cellFormatter: 'riskDifferenceLow',
        formatterAttrsObserve: ['type', 'lowControlRisk', 'effectSize', 'effectMeasure', 'confidenceIntervalFrom', 'confidenceIntervalTo', 'absDenominator', 'type', 'eventType'],
        footnotesAttrs: {
          common: []
        }
      }, {
        contentDefaultClass: 'moderate-value',
        cellFormatter: 'riskDifferenceModerate',
        formatterAttrsObserve: ['type', 'moderateControlRisk', 'effectSize', 'effectMeasure', 'confidenceIntervalFrom', 'confidenceIntervalTo', 'absDenominator', 'type', 'eventType'],
        footnotesAttrs: {
          common: []
        }
      }, {
        contentDefaultClass: 'high-value',
        cellFormatter: 'riskDifferenceHigh',
        formatterAttrsObserve: ['type', 'highControlRisk', 'effectSize', 'effectMeasure', 'confidenceIntervalFrom', 'confidenceIntervalTo', 'absDenominator', 'type', 'eventType'],
        footnotesAttrs: {
          common: []
        }
      }, {
        contentDefaultClass: 'main-value',
        cellFormatter: 'riskDifference',
        formatterAttrsObserve: ['type', 'controlCount', 'controlTotal', 'effectSize', 'effectMeasure', 'confidenceIntervalFrom', 'confidenceIntervalTo', 'absDenominator', 'otherMeasure', 'units', 'unitsType'],
        footnotesAttrs: {
          common: ['effectMeasure', 'otherMeasure', 'effectSize', 'unitsType', 'confidenceIntervalFrom', 'confidenceIntervalTo']
        }
      }
    ]
  },
  'eventRatesWithControl': {
    editType: EditView.TYPES.COMPOUND,
    cellFormatter: 'eventRatesWithControl',
    formatterAttrsObserve: ['type', 'controlCount', 'controlTotal', 'effectMeasure'],
    footnotesAttrs: {
      common: ['controlCount', 'controlTotal']
    }
  },
  'eventRatesWithIntervention': {
    editType: EditView.TYPES.COMPOUND,
    cellFormatter: 'eventRatesWithIntervention',
    formatterAttrsObserve: ['type', 'interventionCount', 'interventionTotal', 'effectMeasure'],
    footnotesAttrs: {
      common: ['interventionCount', 'interventionTotal']
    }
  },
  'noOfParticipantsStudiesDiagnostic': {
    editType: EditView.TYPES.COMPOUND,
    cellFormatter: 'noOfParticipantsStudiesDiagnostic',
    formatterAttrsObserve: ['noOfStudies', 'noOfPatients']
  },
  'narrativeDesc': {
    editType: EditView.TYPES.REACT_CELL_EDIT_VIEW
  },
  'complicationsPrevalencesDesc': {
    editType: EditView.TYPES.COMPOUND
  },
  'inconclusivePrevalencesDesc': {
    editType: EditView.TYPES.COMPOUND
  },
  'events': {
    editType: EditView.TYPES.COMPOUND,
    cellFormatter: 'effectCount',
    formatterAttrsObserve: ['type', 'effectCount', 'effectTotal'],
    footnotesAttrs: {
      common: ['effectCount', 'effectTotal']
    }
  },
  'individuals': {
    editType: EditView.TYPES.COMPOUND,
    cellFormatter: 'effectTotal',
    formatterAttrsObserve: ['effectCount', 'effectTotal'],
    footnotesAttrs: {
      common: ['effectCount', 'effectTotal']
    }
  },
  'rate': {
    editType: EditView.TYPES.COMPOUND,
    cellFormatter: 'effectRate',
    formatterAttrsObserve: ['effectCount', 'effectTotal', 'effectDenominator', 'effectRate', 'effectOtherDenominator', 'effectDuration', 'effectOtherDuration', 'confidenceIntervalFrom', 'confidenceIntervalTo', 'type', 'effectMeasure', 'units'],
    footnotesAttrs: {
      common: ['effectCount', 'effectTotal']
    }
  },
  "riskWithIntervention": {
    cellFormatter: 'riskWithIntervention',
    formatterAttrsObserve: ['changeScoreInControlsValue', 'units', 'effectSize', 'showFinalScoreInControls']
  }
};

outcomeCellEditViewTypes.riskWithControl = outcomeCellEditViewTypes.control;

module.exports = outcomeCellEditViewTypes;
